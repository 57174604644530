<template>
  <div
      class="h-100 material-icons-loaded bg-concord-near-white concord-dark-gray fw4 h-100 mh0 pa0 roboto  ember-application application   pace-done"
      style="height: 100vh; overflow-y: hidden">
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-side-menu :active-index="5"></contract-side-menu>
        <div class="right-panel">
          <MetaRightSideHeader>
            <template #title>
              编辑审批流程
            </template>
            <template #middle-right>
              <el-button class="rect-btn" type="danger" size="small" @click="deleteApprovalProcess" data-tag-id="delete-meta-approval-process"
                         v-if="metaApprovalProcessId" :disabled="metaApprovalProcess.approvalProcess.name==='管理员审批'||!metaApprovalProcess.canDelete ||!checkUserBehaviorPermission(userInfo.mainSiteTeamId,BehaviorPermission.MANAGE_TEAM_APPROVAL_FLOW)">删除
              </el-button>
              <el-button class="border-rect-btn" size="small" @click="$router.back()" data-tag-id="cancel-edit-meta-approval-process">取消</el-button>
              <el-button class="rect-btn" type="primary" size="small" data-tag-id="save-meta-approval-process" @click="saveMetaApprovalProcess(null)"
                         :disabled="saveBtnDisabled">
                保存
              </el-button>
            </template>
          </MetaRightSideHeader>

          <div class="content-container shadow bg-white">


            <div class="flex flex-column">


              <div class="approval-label">

                <span>流程名称</span>
                <el-tooltip content="创建合同的用户在为合同配置审批流程时，将根据此名称进行选择。" placement="top">
                  <span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon-path-icon fill" viewBox="0 0 48 48" width="10" height="10"><rect width="48" height="48" fill="white" fill-opacity="0.01"></rect><path d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z" fill="none" stroke="#333" stroke-width="4" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M24 11C25.3807 11 26.5 12.1193 26.5 13.5C26.5 14.8807 25.3807 16 24 16C22.6193 16 21.5 14.8807 21.5 13.5C21.5 12.1193 22.6193 11 24 11Z" fill="#333"></path><path d="M24.5 34V20H23.5H22.5" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M21 34H28" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </span>
                </el-tooltip>
              </div>

              <el-input ref="nameEdit" v-model="metaApprovalProcess.approvalProcess.name" style="width: 400px;margin-bottom: 10px" v-if="nameEditMode"
                        input-style="border-radius:0;border:0;border-bottom:solid 1px #999;background:#F4F4F4"
                        size="small" placeholder="请输入类别名" @blur="nameEditMode=false"></el-input>
              <div v-else  class="content-label"><span style="margin-right: 10px">{{metaApprovalProcess.approvalProcess.name}}</span><svg-icon @click="onNameEditActive" data-tag-id="edit-meta-approval-process-name" :name="'edit'" :height="16" :width="16"></svg-icon></div>
              <div class="approval-label">

              <span>适用范围</span>
                <el-tooltip content="创建合同的用户在配置审批流程时可见此描述。" placement="top">
                  <span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon-path-icon fill" viewBox="0 0 48 48" width="10" height="10"><rect width="48" height="48" fill="white" fill-opacity="0.01"></rect><path d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z" fill="none" stroke="#333" stroke-width="4" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M24 11C25.3807 11 26.5 12.1193 26.5 13.5C26.5 14.8807 25.3807 16 24 16C22.6193 16 21.5 14.8807 21.5 13.5C21.5 12.1193 22.6193 11 24 11Z" fill="#333"></path><path d="M24.5 34V20H23.5H22.5" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M21 34H28" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </span>
                </el-tooltip>
              </div>
              <el-input ref="descEdit" v-if="descriptionEditMode" v-model="metaApprovalProcess.approvalProcess.description" style="width: 400px;"
                        input-style="border-radius:0;border:0;border-bottom:solid 1px #999;background:#F4F4F4"
                        size="small" placeholder="请输入描述" @blur="descriptionEditMode=false"></el-input>
              <div v-else  class="content-label" style="margin-bottom: 0"><span style="margin-right: 10px">{{metaApprovalProcess.approvalProcess.description}}</span><svg-icon @click="onDescptionEditActive" data-tag-id="edit-meta-approval-process-desc" :name="'edit'" :height="16" :width="16"></svg-icon></div>
              <el-divider></el-divider>
              <div class="approval-label">
              <span class="gray" style="margin-bottom: 20px">审批流程设置</span>
                <el-tooltip content="可设置审批步骤、人员与策略。" placement="top">
                  <span>
                  <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon-path-icon fill" viewBox="0 0 48 48" width="10" height="10"><rect width="48" height="48" fill="white" fill-opacity="0.01"></rect><path d="M24 44C29.5228 44 34.5228 41.7614 38.1421 38.1421C41.7614 34.5228 44 29.5228 44 24C44 18.4772 41.7614 13.4772 38.1421 9.85786C34.5228 6.23858 29.5228 4 24 4C18.4772 4 13.4772 6.23858 9.85786 9.85786C6.23858 13.4772 4 18.4772 4 24C4 29.5228 6.23858 34.5228 9.85786 38.1421C13.4772 41.7614 18.4772 44 24 44Z" fill="none" stroke="#333" stroke-width="4" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M24 11C25.3807 11 26.5 12.1193 26.5 13.5C26.5 14.8807 25.3807 16 24 16C22.6193 16 21.5 14.8807 21.5 13.5C21.5 12.1193 22.6193 11 24 11Z" fill="#333"></path><path d="M24.5 34V20H23.5H22.5" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path><path d="M21 34H28" stroke="#333" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                  </span>
                </el-tooltip>
              </div>
              <div class="approval-process">
                <el-table :data="metaApprovalProcess.approvalStages"
                >
                  <el-table-column
                      label="步骤"
                      width="200"

                  >
                    <template #default="scope">
                      <div style="width: 30px;text-align: center">{{ scope.$index + 1 }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                      label="审批人"
                      min-width="200"
                  >
                    <template #default="scope">

                      <div class="flex items-center" v-if="scope.row.orders.length>0">
                        <approval-member :orders="scope.row.orders" main_placement="bottom" sub_placement="right-start">
                          <template #reference>


                            <user-icon style="margin-right: -8px" v-for="(order,index) in scope.row.orders.slice(0,5)" :key="index" :url="order.approverHeadImgUrl" :name="order.approverName" :uid="order.approverName"></user-icon>
                            <user-icon v-if="scope.row.orders.length>5" size="small" uid="count" :name="'+'+(scope.row.orders.length-5)" :count="2"></user-icon>


                          </template>
                        </approval-member>
                        <div style="margin-left: 10px" @click="showStageApprover(scope.row.orders)" data-tag-id="edit-meta-approval-process-approver">
                          <i class="material-icons concord-blue pointer" style="vertical-align: middle">mode_edit</i>
                        </div>

                      </div>

                      <div v-else @click="showStageApprover(scope.row.orders)" class="flex items-center pointer" data-tag-id="add-meta-approval-process-approver" style="font-size: 16px">
                        <i class="material-icons concord-blue pointer">add</i><span class="concord-blue">添加审批人</span>
                      </div>

                    </template>

                  </el-table-column>
                  <el-table-column
                      label="策略"
                      min-width="200"
                  >
                    <template #default="scope">
                      <el-select v-model="metaApprovalProcess.approvalStages[scope.$index].strategy"
                                 placeholder="Select" size="small">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </template>

                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="100"

                  >
                    <template #default="scope">
                      <el-button type="danger" class="rect-btn" size="small" @click="deleteStage(scope.$index)" data-tag-id="delete-meta-approval-stage"
                                 v-if="scope.$index!=metaApprovalProcess.approvalStages.length-1">删除
                      </el-button>
                    </template>

                  </el-table-column>
                </el-table>
              </div>

<!--              <div class="calendar-manager">-->
<!--                <h4 class="gray" style="margin: 0 0 20px 0">日历管理人员</h4>-->
<!--                <di><span>Assigned to</span></di>-->
<!--                <el-divider style="margin: 5px 0"></el-divider>-->
<!--                <div class="flex">-->
<!--                  <div v-if="initFinished">-->
<!--                    <members :members="metaApprovalProcess.calendarManagers" main_placement="top" sub_placement="right">-->
<!--                      <template #reference>-->
<!--&lt;!&ndash;                      <div v-for="(item,index) in metaApprovalProcess.calendarManagers" :key="index"&ndash;&gt;-->
<!--&lt;!&ndash;                          :style="{'background-color':item.headImgUrl?'unset':getHashColor(item.userName)}"&ndash;&gt;-->
<!--&lt;!&ndash;                          class="stage-order">&ndash;&gt;-->
<!--&lt;!&ndash;                          <img v-if="item.headImgUrl"&ndash;&gt;-->
<!--&lt;!&ndash;                               :src="item.headImgUrl" width="28" height="28"/>&ndash;&gt;-->
<!--&lt;!&ndash;                          <span v-else>{{ item.userName ? item.userName.substr(0, 1) : item.managerId.substr(0, 1) }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      </div>&ndash;&gt;-->
<!--                        <user-icon v-for="(item,index) in metaApprovalProcess.calendarManagers" :key="index" :url="item.headImgUrl" :name="item.userName" :uid="item.userName">-->
<!--                        </user-icon>-->
<!--                      </template>-->
<!--                    </members>-->
<!--                  </div>-->

<!--                  <div style="margin-left: 5px" @click="showCalendarManagers">-->
<!--                    <i class="material-icons concord-blue pointer" style="vertical-align: middle">mode_edit</i>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <contract-permission-window :title="selectUsersTitle" :approveMembers="selectUsers" ref="window" :show_permission="false" :confirm-call-back="onWindowConfirm">

  </contract-permission-window>
</template>

<script>
import ContractSideMenu from "../components/ContractSideMenu";
import {getHashColor} from "../util";
import {
  deleteMetaApprovalProcessById,
  getMetaApprovalProcess, getOrganizationUser,
  getTeamUser, getTeamOrganizations,
  saveTeamMetaApprovalProcess, updateTeamMetaApprovalProcess
} from "../api/api";
import OrganizationMember from "../components/ApprovalMember";
import ApprovalMember from "../components/ApprovalMember";
import Members from "../components/Members";
import ContractPermissionWindow from "./contract/ContractPermissionWindow";
import UserIcon from "../components/UserIcon";
import {checkUserBehaviorPermission,BehaviorPermission} from "../components/common/permissionUtil";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import {globalTagClick} from "../assets/js/tag/tag";
import {ElMessageBox} from "element-plus";
import SvgIcon from "../components/SvgIcon/svgIcon";

export default {
  name: "MetaApprovalEdit",
  components: {
    SvgIcon,
    MetaRightSideHeader,
    UserIcon, ContractPermissionWindow, Members, ApprovalMember, OrganizationMember, ContractSideMenu},
  data() {
    return {
      getHashColor,
      metaApprovalProcess: {
        approvalProcess: {
          name: '',
          description: '',

        },
        approvalStages: [{strategy: 1, sortIndex: 0,orders:[]}],
        canDelete:true
      },
      options: [
        {value: 0, label: "全部审批人通过"},
        {value: 1, label: "任一审批人通过"},
      ],
      metaApprovalProcessId: null,
      showSelectUserDialogVisible: false,
      selectUsers: [],
      searchKey: '',
      currentStage: null,
      teamMembers: [],
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      initFinished: false,
      showSelectCalendarManagerDialogVisible: false,
      calenderManagers: [],
      nameSearchKey: '',
      memberType:0,
      selectUsersTitle:'',
      checkUserBehaviorPermission,
      BehaviorPermission,
      nameEditMode:false,
      descriptionEditMode:false
    }
  },
  computed:{
    saveBtnDisabled(){
      if(!checkUserBehaviorPermission(this.userInfo.mainSiteTeamId,BehaviorPermission.MANAGE_TEAM_APPROVAL_FLOW))return true;
      let hasEmptyStage = false;
      for(let i in this.metaApprovalProcess.approvalStages){
        let item = this.metaApprovalProcess.approvalStages[i];
        if(item.orders.length===0&&i!=this.metaApprovalProcess.approvalStages.length-1){
          hasEmptyStage=true;
          break;
        }
      }
      return this.metaApprovalProcess.approvalProcess.name=='' ||this.metaApprovalProcess.approvalStages.length==1  ||hasEmptyStage
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.metaApprovalProcessId = this.$route.params.id
    }
    else{
      this.nameEditMode=true;
      this.descriptionEditMode=true;
    }
    this.loadData();
  },
  methods: {
    loadData: function () {
      if (this.metaApprovalProcessId) {
        getMetaApprovalProcess(this.metaApprovalProcessId).then(res => {
          this.metaApprovalProcess = res.data.data;
          this.metaApprovalProcess.approvalStages.push({strategy: 1,sortIndex: this.metaApprovalProcess.approvalStages.length,orders:[]})


        })

      }


    },
    FilterFuc(item) {
      return ((item.uid && item.uid.indexOf(this.searchKey) > -1) || (item.email && item.email.indexOf(this.searchKey) > -1) || (item.name && item.name.indexOf(this.searchKey) > -1))
    },
    FilterOrg(item) {
      return (item.uid && item.uid.indexOf(this.nameSearchKey) >-1) || (item.userName && item.userName.indexOf(this.nameSearchKey) > -1)
    },
    userSelect(index) {

      this.selectUsers = this.teamMembers.filter((item) => item.checked)
      // if(this.teamMembers[index].checked){
      //   this.selectUsers.push({approver:this.teamMembers[index].uid})
      // }else{
      //   this.
      // }

    },
    removeUser(index) {
      this.selectUsers[index].checked = false;
      this.selectUsers = this.teamMembers.filter((item) => item.checked);
    },

    addRow() {
      if (this.metaApprovalProcess.approvalStages[this.metaApprovalProcess.approvalStages.length - 1].orders.length > 0) {
        this.metaApprovalProcess.approvalStages.push(
            {
              strategy: 1,
              sortIndex: this.metaApprovalProcess.approvalStages.length,
              orders: []
            })

      }

    },

    saveMetaApprovalProcess() {
      globalTagClick('meta-approval-edit-save-approval-process', {metaApprovalProcess:this.metaApprovalProcess});
      this.metaApprovalProcess.approvalStages.splice(this.metaApprovalProcess.approvalStages.length - 1, 1)
      this.metaApprovalProcess.approvalStages.forEach((item) => {
          item.id = null
        item.orders.forEach((order)=>{
          order.id=null;
        })

       }
      );



      this.saveOrUpdateTeamMetaApproval( this.metaApprovalProcess).then(res => {
        if (res.status == 200) {
          if (res.data.code == 0) {
            this.$router.back();
          } else {
            this.addRow();
            this.$message.error("审批流名称已存在")
          }
        }
      }).catch(e => {
        this.$message.error("网络错误")
        console.error("saveTeamMetaApprovalProcess err=>" + e)
      })
    },
    saveOrUpdateTeamMetaApproval(metaApprovalProcess) {
      if(metaApprovalProcess.approvalProcess.id){
        return updateTeamMetaApprovalProcess(metaApprovalProcess);
      }else{
        return saveTeamMetaApprovalProcess(metaApprovalProcess);
      }
    },
    deleteStage(index) {
      this.metaApprovalProcess.approvalStages.splice(index, 1);
      let i = 0;
      this.metaApprovalProcess.approvalStages.forEach((item) => item.sortIndex = i++);
    },
    deleteApprovalProcess() {
      ElMessageBox.confirm("确定要删除吗?","提示",{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
        dangerouslyUseHTMLString: true,
      }).then(()=>{
        deleteMetaApprovalProcessById(this.metaApprovalProcessId).then(res => {
          this.$router.back();
        }).catch(err => {
          this.$message.error("网络错误");
          console.error(err)
        })
      })

    },
    showCalendarManagers(){
      this.selectUsers=this.metaApprovalProcess.calendarManagers
      this.selectUsersTitle="选择日历管理员"
      this.$refs.window.openWindow()

      this.memberType=1;

    },
    showStageApprover(member){
      this.selectUsers=member
      this.selectUsersTitle='选择审批人'
      this.$refs.window.openWindow()

      this.memberType=0;
    },
    onWindowConfirm(data){
      this.selectUsers.length=0;
      if(this.memberType==1) {
        data.forEach((item) => {
          if (item.type == 'user') {
            this.selectUsers.push({
              managerId: item.objectId,
              managerType: 0,
              userName: item.objectName,
              headImgUrl: item.headImgUrl
            })
          } else {
            this.selectUsers.push({
              managerId: item.objectId,
              managerType: 1,
              userName: item.objectName,
              headImgUrl: item.headImgUrl
            })
          }
        })
      }else if(this.memberType==0){
        let index=0;
        data.forEach((item) => {
          if (item.type == 'user') {
            this.selectUsers.push({
              approver: item.objectId,
              approverType: 0,
              approverName: item.objectName,
              approverHeadImgUrl: item.headImgUrl,
              sortIndex:index++
            })
          } else {
            this.selectUsers.push({
              approver: item.objectId,
              approverType: 1,
              approverName: item.objectName,
              approverHeadImgUrl: item.headImgUrl,
              sortIndex:index++
            })
          }
        })
        this.addRow();
      }

    },
    onNameEditActive(){
      this.nameEditMode=true;
      this.$nextTick(()=>{
        this.$refs.nameEdit.focus();
      })


    },
    onDescptionEditActive(){
      this.descriptionEditMode=true;
      this.$nextTick(()=>{
        this.$refs.descEdit.focus();
      })
    }

  }
}
</script>

<style scoped>
.right-panel {
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
  height: 100%;
  width: calc(100% - 283px);
}

.content-container {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.approval-process >>> .el-table__body-wrapper {
  overflow: unset;
}


.approval-process >>> .el-table {
  overflow: unset;
}

.approval-process >>> .el-table::before {
  height: 0px;
}

.approval-process >>> .el-table td {

}

.approval-process >>> .el-table .cell {
  overflow: unset;
}

.stage-order {
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  border-radius: 20px;
  color: white;


  overflow: hidden;
  flex-shrink: 0;
  margin-right: -3px;
  border: solid 1px rgba(255, 255, 255, 0.3);
}

.approval-process >>> .el-input__inner {
  border-radius: 0;
  border: 0;
  background: transparent;

}

.calendar-manager {
  margin-top: 40px;
  width: 50%;
}

.approval-label{
  height: 20px;
  font-size: 16px;
  color: gray;

}
.approval-label span{
  margin-right: 5px;
}
.approval-label svg{
  vertical-align: top;

}

.content-label{
  margin: 10px 0;
  font-size: 16px

}

.content-label svg{
  display: none;
}

.content-label:hover svg{
  display: unset;
  cursor:pointer;
}

</style>
